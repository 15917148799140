import Home1 from "./Store/Home1.jpg";
import Home2 from "./Store/Home2.webp";
import Home3 from "./Store/Home3.jpg";
import Home4 from "./Store/Home4.webp";
import Home5 from "./Store/Home5.jpg";
import Home6 from "./Store/Home6.jpg";
import Home7 from "./Store/Home7.png";
import Logo from "./Store/Logo.png";
import LogoDark from "./Store/Logo Dark.png";
import LogoCircle from "./Store/Logo Circle.png";
import LogoCircleDark from "./Store/Logo Circle Dark.png";
import Computer3d from "./Store/computer3d.png";
import React from "react";
import Accordion from "./Store/AccordionApp.png";
import ChainDev from "./Store/chain-dev.png";
import Estate from "./Store/estate.png";
import EstateTablet from "./Store/estate-tablet.png";
import FileManagement from "./Store/file-management.png";
import FileManagementTablet from "./Store/file-management-tablet.png";
import Germen from "./Store/Germen.png";
import Glassmorphic from "./Store/Glassmorphic.png";
import Justice from "./Store/Justice.png";
import Pegasus from "./Store/Pegasus.png";
import VBud from "./Store/vBud.png";
import Image31 from "./Store/image31.png";
import ThankYou from "./Store/thankyou.png";
import Abraham from "./teamImg/abraham.jpg";
import Husisan from "./teamImg/husian.jpeg";
import Rehmat from "./teamImg/rehmat.png";
import Saddam from "./teamImg/saddam.jpeg";
import Shoaib from "./teamImg/sohaib.jpeg";
import Kamran from '../teams/Kamran_20_11zon.webp'
import Adil from '../teams/Adilanees.jpg'
import Awais from '../teams/Awais_15_11zon.webp'
import Faraz from '../teams/Faraz_16_11zon.webp'
import Imala from '../teams/Imala Muneer_19_11zon.webp'
import hasan from '../teams/hassanjs_18_11zon.webp'
import Laraib from '../teams/Laraib Chaudhary1_21_11zon.webp'
import Malik from '../teams/Malik_22_11zon.webp'
import mamfaiza from '../teams/mamfaiza_23_11zon.webp'
import Rida from '../teams/Rida Asif_25_11zon.webp'
import Musman from '../teams/MuhammadUsman_24_11zon.webp'
import Usmanzaheer from '../teams/Usman Zaheer_4_11zon.webp'
import usmanali from '../teams/Usmanb_5_11zon.webp'
import Saqib from '../teams/Saqib Ali Shahzad_27_11zon.webp'
import sufyan from '../teams/Sufianch.jpg'
import ShoaibAlam from '../teams/ShoaibAlam_2_11zon.webp'
import Warda from '../teams/WardaMaryam_6_11zon.webp'
import Anwar from "../teams/Anwar_13_11zon.webp"
import zohaibswati from '../teams/Zohaib Sawati_7_11zon.webp'

import sheyan from '../teams/Sheyan_29_11zon.webp'

import Shahmeer from '../teams/Shahmeerkhan_28_11zon.webp'



import AhmedShehzad from '../teams/Ahmed Shehzad_10_11zon.webp'

import Alihasan from '../teams/Alihasanjpg_11_11zon.webp';
import Alisha from '../teams/Alisha .jpg';
import Owais from '../teams/M.Owais Qarni.jpg';
import Survey1 from './Store/Survey/screencapture-api-leadconnectorhq-widget-survey-Afe7it7D7HgJAWqb2vAb-2024-08-01-02_14_52.webp';
import Survey2 from './Store/Survey/screencapture-api-leadconnectorhq-widget-survey-OIUMnKvAfiBKSJq9T6uz-2024-08-01-02_13_09.webp';
import Survey3 from './Store/Survey/screencapture-api-leadconnectorhq-widget-survey-XWjVRQrF2hkc300ckVhi-2024-08-01-02_15_45.webp';
import Barberfunnel from './Store/Projects-web/Barberfunnel.webp';
import Digitalbussiness from './Store/Projects-web/Digital bussiness.webp';
import fitness from './Store/Projects-web/fitnessfunnel.webp';
import Healthcare from './Store/Projects-web/Healthcare.webp';
import RealState from './Store/Projects-web/RealState.webp';
import SAAS from './Store/Projects-web/SAAS Solution.webp';
import Barber2 from './Store/Projects-web/Xortlogic BARber.webp';
import lightDashboard from './Store/Dashboards/Dashboard (light mode) 5.webp';
import Darkdashboard from './Store/Dashboards/Dashboard(Dark Mode).webp'
import form1 from './Store/forms/Form.png';
import form2 from './Store/forms/form2.png';
import Sajjad from '../teams/Sajjad Ahmad_26_11zon.webp';
import Ayaz from '../teams/Ayaz.webp';
import Abdul_Hanan from '../teams/Hannan Madni.jpeg';
export default function Images({ src, className, ...props }) {
  let img;
  switch (src) {
    case "logo":
      img = Logo;
      break;
    case "Home1":
      img = Home1;
      break;
    case "Home2":
      img = Home2;
      break;
    case "Home3":
      img = Home3;
      break;
    case "Home4":
      img = Home4;
      break;
    case "Home5":
      img = Home5;
      break;
    case "Home6":
      img = Home6;
      break;
    case "Home7":
      img = Home7;
      break;
    case "Logo":
      img = Logo;
      break;
    case "Logo Dark":
      img = LogoDark;
      break;
    case "Logo Circle":
      img = LogoCircle;
      break;
    case "Logo Circle Dark":
      img = LogoCircleDark;
      break;
    case "computer3d":
      img = Computer3d;
      break;
    case "zeta dashboard":
      img = Glassmorphic;
      break;
    case "accordion app":
      img = Accordion;
      break;
    case "chain dev":
      img = ChainDev;
      break;
    case "estate":
      img = Estate;
      break;
    case "estate tablet":
      img = EstateTablet;
      break;
    case "file management":
      img = FileManagement;
      break;
    case "file management tablet":
      img = FileManagementTablet;
      break;
    case "germen":
      img = Germen;
      break;
    case "justice":
      img = Justice;
      break;
    case "pegasus":
      img = Pegasus;
      break;
    case "v bud":
      img = VBud;
      break;
    case "image31":
      img = Image31;
      break;
    case "thank you":
      img = ThankYou;
      break;
    case "form1":
      img = form1;
      break;
    case "form2":
      img = form2;
      break;
    case "Survey1":
      img = Survey1;
      break;
    case "Survey2":
      img = Survey2;
      break;
    case "Survey3":
      img = Survey3;
      break;
    case "Barberfunnel":
      img = Barberfunnel;
      break;
    case "Digitalbussiness":
      img = Digitalbussiness;
      break;
    case "fitness":
      img = fitness;
      break;
    case "Healthcare":
      img = Healthcare;
      break;
    case "RealState":
      img = RealState;
      break;
    case "SAAS":
      img = SAAS;
      break;
    case "Barber2":
      img = Barber2;
      break;
    case "light dashboard":
      img = lightDashboard;
      break;
    case "Darkdashboard":
      img = Darkdashboard;
      break;
    case "abraham":
      img = Abraham;
      break;
    case "husian":
      img = Husisan;
      break;
    case "saddam":
      img = Saddam;
      break;
    case "shoaib":
      img = Shoaib;
      break;
    case "rehmat":
      img = Rehmat;
      break;
    case "kamran":
      img = Kamran;
      break;
    case "Adil":
      img = Adil;
      break;
    case "Awais":
      img = Awais;
      break;
    case "Imala":
      img = Imala;
      break;

    case "Faraz":
      img = Faraz;
      break;
    case "hasan":
      img = hasan;
      break;
    case "Laraib":
      img = Laraib;
      break;
    case "Malik":
      img = Malik;
      break;
    case "mamfaiza":
      img = mamfaiza;
      break;
    case "Rida":
      img = Rida;
      break;
    case "M.Usman":
      img = Musman;
      break;
    case "Usmanzaheer":
      img = Usmanzaheer;
      break;
    case "Usmanb":
      img = usmanali;
      break;
    case "Saqib":
      img = Saqib;
      break;
    case "Sufyan":
      img = sufyan;
      break;
    case "ShoaibAlam":
      img = ShoaibAlam;
      break;
    case "Warda":
      img = Warda;
      break;
    case "Zohaib Sawati":
      img = zohaibswati;
      break;
    case "Sheyan":
      img = sheyan;
      break;
    case "Shahmeer":
      img = Shahmeer;
      break;

    case "Ahmed Shehzad":
      img = AhmedShehzad;
      break;
    case "Alihasan":
      img = Alihasan;
      break;
    case "Alisha":
      img = Alisha;
      break;
    case "Anwar":
      img = Anwar;
      break;
    case "Sajjad":
      img = Sajjad;
      break;
    case "Ayaz":
      img = Ayaz;
      break;
    case "Owais":
      img = Owais;
      break;
    case "Hannan":
      img = Abdul_Hanan;
      break;
    default:
      img = src;
  }
  return <img {...props} className={className} src={img} alt={"Error"} />;
}
