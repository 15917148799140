import Logo from "../images/Store/Logo Circle.png";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../icons/Icon";
import { memo } from "react";

const Navbar = () => {
  const navigate = useNavigate();
  const randStr = () => Math.random().toString(32).substr(2);
  const menus = ["Home", "About", "Services", "Portfolio", "Contact", "Team"];

  const [activeNav, setActiveNav] = useState("Home");
  const [toggleMenu, setToggleMenu] = useState(false);
  const hammenu = useRef(null);

  useEffect(() => {
    let action = toggleMenu ? "add" : "remove";
    hammenu.current?.classList[action]("open");

    document.body.style.overflow = toggleMenu ? "hidden" : "auto"; // Prevent scrolling when menu is open

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [toggleMenu]);

  function scrollIntoView(sel) {
    const activeLink = sel.charAt(0).toUpperCase() + sel.slice(1);
    setActiveNav(activeLink);
    const elm = document.querySelector("#" + sel);
    if (elm) {
      elm.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleMenuClick() {
    setToggleMenu((state) => !state);
  }

  const logo = (
    <Link to="/"
      onClick={(e) => {
        if (window.location.pathname === "/") {
          e.preventDefault();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }}>
      <img
        className="w-12 object-cover"
        loading="eager"
        alt="Company Logo"
        src={Logo}
      />
    </Link>
  );

  useEffect(() => {
    const menuLink = document.querySelectorAll(".menuLink");

    function handleMenuMouseEnter({ target }) {
      menuLink.forEach(
        (menu) => target !== menu && menu.classList.add("disable")
      );
    }

    function handleMenuMouseLeave() {
      menuLink.forEach((menu) => menu.classList.remove("disable"));
    }

    menuLink.forEach((menu) => {
      menu.removeEventListener("mouseenter", handleMenuMouseEnter);
      menu.removeEventListener("mouseleave", handleMenuMouseEnter);

      menu.addEventListener("mouseenter", handleMenuMouseEnter);
      menu.addEventListener("mouseleave", handleMenuMouseLeave);
    });
  }, []);

  const company = {
    address: (
      <span>
        541 Melville Geek,
        <br /> Palo Alto, CA 94301
      </span>
    ),
    phone: "+92 366 650 2064",
    email: "info@xortlogix.com",
    social_media: {
      Facebook: "#",
      LinkedIn: "#",
      Instagram: "#",
      Twitter: "#",
    },
  };

  return (
    <header className="sticky top-0 left-0 w-full sm:px-8 z-50 bg-transparent backdrop-filter backdrop-blur-xl ">
      <div className="flex items-center justify-between py-5 px-4 md:px-8 lg:px-12 max-w-full text-base text-white font-small">
        {logo}
        <div className=" rounded-md hidden lg:flex flex-row flex-wrap items-center justify-center p-1.5 box-border gap-[10px] max-w-full">
          {menus.map((menu) => (
            <Link
              to={`${menu === "Team"
                ? "/team"
                : menu === "Portfolio"
                  ? "/portfolio"
                  : "/"}`}
              key={randStr()}
              onClick={() => {
                scrollIntoView(menu.toLowerCase());
              }}
              className={`btn btn-primary-outline text-sm ${activeNav === menu ? "active" : ""
                }`}
            >
              {menu}
            </Link>
          ))}
        </div>
        <div className="flex gap-4">
          <button
            onClick={() => navigate("/calendar")}
            className="btn btn-primary !text-[18px]"
          >
            <span className="block p-0.5">Get Started</span>
          </button>
          <div
            className="lg:hidden cursor-pointer flex justify-center items-center"
            onClick={handleMenuClick}
          >
            <Icon
              className={`w-10 ${toggleMenu ? "active" : ""}`}
              name={"hamburger"}
            />
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        ref={hammenu}
        className={`hammenu fixed inset-0 z-40 bg-white transform ${toggleMenu ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out lg:hidden`}
      >
        {/* Logo & close menu button */}
        <div className="flex justify-between w-full px-4 py-5 sm:px-10">
          {logo}
          <div
            className="cursor-pointer flex justify-center items-center"
            onClick={handleMenuClick}
          >
            <Icon
              className={`w-10 ${toggleMenu ? "active" : ""}`}
              name={"hamburger"}
            />
          </div>
        </div>

        <div className="flex flex-col h-full p-8">
          {menus.map((menu) => (
            <div key={randStr()} className="border-t py-6">
              <Link
                to={`${menu === "Team"
                  ? "/team"
                  : menu === "Portfolio"
                    ? "/portfolio"
                    : "/"}`}
                onClick={() => {
                  setTimeout(scrollIntoView, 300, menu.toLowerCase());
                  handleMenuClick();
                }}
                style={{ color: "black" }}
                className="menuLink block text-center font-semibold tracking-wider uppercase text-2xl"
              >
                {menu}
              </Link>
            </div>
          ))}

          {/* Sidebar Content */}
          <div className="mt-10">
            {/* Right Sidebar Address */}
            <div className="mb-8">
              <h6 className="sub-title mb-2 opacity-70">Address</h6>
              <h5 className="text-xl">{company.address}</h5>
            </div>

            {/* Right Sidebar Social Media */}
            <div className="mb-8">
              <h6 className="sub-title mb-2 opacity-70">Social Media</h6>
              <ul className="flex flex-col">
                {Object.keys(company.social_media).map((media) => (
                  <li key={randStr()} className="mb-2">
                    <a href={company.social_media[media]} className="link">
                      {media}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Right Sidebar Contact Us */}
            <div className="mb-8">
              <h6 className="sub-title mb-2 opacity-70">Contact Us</h6>
              <h5>
                <a
                  className="link"
                  href={"mailto:" + encodeURIComponent(company.email)}
                >
                  {company.email}
                </a>
              </h5>
              <h5 className="mt-2">
                <a
                  className="link pr-0.5"
                  href={"tel:" + encodeURIComponent(company.phone)}
                >
                  {company.phone}
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default memo(Navbar);
